import React from 'react';
import './Footer.css';

const Footer = () => (
  <div className='footer'>
    <h3>©2025 Ziad Nawar. All rights reserved.</h3>
  </div>
);

export default Footer;
